import Head from "next/head"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import env from "@config/env"
const { BASE_META_URL, DEFAULT_META_IMAGE } = env
const MIN_WIDTH_PAGE = 992

const DefaultHeadMeta = (props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { title, desc, siteName, image } = props

  const innerWidth = typeof window !== "undefined" ? window.innerWidth : ""
  const relativePath =
    router.locale === "vi" ? router.route : router.locale + router.route
  const url = `${BASE_META_URL}${relativePath}`

  return (
    <Head>
      <title>{title || t("DEFAULT_META_TITLE")}</title>

      <meta name="application-name" content="Power Block App" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Power Block App" />
      <meta name="description" content={t("DEFAULT_META_DESCRIPTION")} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/icons/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#211f5a" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#FFFFFF" />

      <link rel="manifest" href="/manifest.json" />
      <link rel="canonical" href={url} />

      {/* <!-- Primary Meta Tags --> */}
      <meta name="title" content={title || t("DEFAULT_META_TITLE")} />
      <meta
        name="description"
        content={desc || t("DEFAULT_META_DESCRIPTION")}
      />

      <meta property="og:locale" content={router.locale} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta
        name="og:title"
        property="og:title"
        content={title || t("DEFAULT_META_TITLE")}
      />
      <meta
        name="og:description"
        property="og:description"
        content={desc || t("DEFAULT_META_DESCRIPTION")}
      />
      <meta
        property="og:site_name"
        content={siteName || "powerblock.fxce.com"}
      />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={image || DEFAULT_META_IMAGE}
      />
      <meta property="og:image:width" content="720" />
      <meta property="og:image:height" content="480" />
      {/* <!-- Twitter --> */}
      {image && (
        <meta
          name="twitter:image"
          content={image || DEFAULT_META_IMAGE}
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title || t("DEFAULT_META_TITLE")} />
      <meta
        name="twitter:description"
        content={desc || t("DEFAULT_META_DESCRIPTION")}
      />
      <meta
        name="viewport"
        content={
          Number(innerWidth) <= MIN_WIDTH_PAGE
            ? `width=${MIN_WIDTH_PAGE}, user-scalable=no`
            : "initial-scale=1.0, width=device-width"
        }
      />
    </Head>
  )
}

export default DefaultHeadMeta
