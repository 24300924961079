import React from "react"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { get } from "lodash"
import { useSelector } from "react-redux"

import Layout from "@components/layout"
import Banner from "@components/landing/Banner"
import HowToStart from "@components/landing/HowToStart"
import FeaturedGame from "@components/landing/FeaturedGame"
import WhyChooseUs from "@components/landing/WhyChooseUs"
import LotteryResults from "@components/landing/LotteryResults"
import DefaultHeadMeta from "@components/next-components/DefaultHeadMeta"

const Home = () => {
  const game = useSelector((state) => {
    return get(state, "rootConfig.game", {})
  })

  const dialedGames = useSelector((state) => {
    return get(state, "rootConfig.dialedGames", [])
  })

  return (
    <>
      <DefaultHeadMeta />

      <Layout>
        <div className="home">
          <div className="section-page">
            <Banner game={game} dialedGames={dialedGames} />

            <HowToStart />
            <FeaturedGame />
            <LotteryResults />
            <WhyChooseUs />

            <div className=""></div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Home

export const getStaticProps = async ({ locale = "en" }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale))
    }
  }
}
