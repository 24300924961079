import { i18n } from "next-i18next"
import env from "@config/env"
import { getIsClient } from "./utils"
const { ARENA_URL, HOME_URL } = env

// export const COOKIE_DOMAIN = IS_PROD ? ".fxce.com" : ".vncdevs.com"

// const encrypt = (text) => {
//   if (!IS_PROD) {
//     return text
//   }
//   if (text && text.length > 30000) {
//     return null
//   }
//   const ciphertext = AES.encrypt(JSON.stringify(text), SECRET_KEY_COOKIE)
//   return ciphertext.toString()
// }

// const decrypt = (text) => {
//   if (!IS_PROD) {
//     return text
//   }
//   try {
//     const bytes = AES.decrypt(text.toString(), SECRET_KEY_COOKIE)
//     const decryptedData = JSON.parse(bytes.toString(utf8))
//     return decryptedData
//   } catch (ex) {
//     return null
//   }
// }

// const getUserCookie = (): { accessToken?: string; refreshToken?: string } => {
//   const fxceUser = Cookies.get(STORAGE_KEY.USER)
//   const defaultValue = {}
//   try {
//     if (fxceUser) {
//       const value = decrypt(fxceUser)
//       const data = value ? JSON.parse(value) : defaultValue
//       return data
//     }
//     return defaultValue
//   } catch {
//     return defaultValue
//   }
// }

// const removeUserCookie = () => {
//   Cookies.remove(STORAGE_KEY.USER, { path: "/", domain: COOKIE_DOMAIN })
// }

// const setUserCookie = (data: RefreshTokenType) => {
//   const { accessToken, refreshToken, refreshTokenExpiryTime } = data
//   const storedValue = {
//     accessToken,
//     refreshToken
//   }
//   let expires: Date | undefined
//   if (!!refreshTokenExpiryTime && typeof refreshTokenExpiryTime === "number") {
//     expires = new Date(refreshTokenExpiryTime)
//   }
//   const value = encrypt(JSON.stringify(storedValue))
//   if (!value) {
//     return
//   }
//   Cookies.set(STORAGE_KEY.USER, value, {
//     path: "/",
//     domain: COOKIE_DOMAIN,
//     secure: IS_PROD,
//     expires,
//     sameSite: "Strict"
//   })
// }

const getHref = (): string => {
  return window.location.href
}
const mapHomeUrl = (path = ""): string => {
  return `${HOME_URL}${path}`
}
const mapSSOUrl = (path: string, params?: string): string => {
  const { language } = i18n
  const isClient = getIsClient()
  const url = `${env.SSO_URL}/${language}${path}`

  if (path.includes("/login") && isClient) {
    return `${url}?pathname=${window.location.href}`
  }

  if (params) {
    return `${url}?${params}`
  }

  return url
}

const mapArenaUrl = (path = ""): string => {
  return `${ARENA_URL}${path}`
}

const validURL = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ) // fragment locator
  return !!pattern.test(str)
}

const showLoading = () => {
  const body = document.querySelector("body")
  body.classList.add("page-loading")
}
const hideLoading = () => {
  const body = document.querySelector("body")
  body.classList.remove("page-loading")
}

export {
  getHref,
  validURL,
  mapSSOUrl,
  mapArenaUrl,
  mapHomeUrl,
  showLoading,
  hideLoading
}
