import React, { memo } from "react"

import Image from "next/image"

const defaultStyle = {
  boxSizing: "border-box",
  display: "inline-block",
  overflow: "hidden",
  width: "initial",
  height: "initial",
  background: "none",
  opacity: "1",
  border: "0px",
  margin: "0px",
  padding: "0px",
  position: "relative",
  maxWidth: "100%"
}

const ImagePriority = (props) => {
  const { priority = true, src, alt = `${src}`, height, width, layout } = props

  return (
    <div style={defaultStyle}>
      <Image
        className="next-image"
        priority={priority}
        src={src}
        alt={alt}
        width={width}
        height={height}
        layout={layout}
      />
    </div>
  )
}

export default memo(ImagePriority)
