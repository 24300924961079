// import Header from "./header"
// import Footer from "./footer"

import dynamic from "next/dynamic"
const Header: any = dynamic(() => import("./header"))
const Footer: any = dynamic(() => import("./footer"))

export default function Layout({ children }) {
  return (
    <div className="layout">
      <Header />
      <div className="layout-body">{children}</div>
      <Footer />
    </div>
  )
}
