import React, { useCallback, useState } from "react"

import { useTranslation, Trans } from "next-i18next"
import Link from "next/link"

import Image from "@components/core/ImagePriority"
import ModalRule from "@components/pages/power-block-game/ModalRule"

const FeaturedGame = () => {
  const { t } = useTranslation()
  const [isOpenModalRule, setIsOpenModalRule] = useState(false)
  const [options, setOptions] = useState({
    initTabSidebar: "2",
    initActiveTab: "0"
  })
  const onToggleRule = useCallback(
    (option) => {
      setOptions(option)
      setIsOpenModalRule(!isOpenModalRule)
    },
    [isOpenModalRule]
  )
  return (
    <div className="box-featured-game">
      <div className="box-title text-uppercase">{t("Featured games")}</div>
      <div className="box-desc">{t("Featured games desc")}</div>
      {isOpenModalRule && (
        <ModalRule
          isOpen={isOpenModalRule}
          toggle={onToggleRule}
          {...options}
        />
      )}
      <div className="content-featured-game">
        <div className="content-item item-powerblockgame">
          <div className="content-item__image">
            <Image
              src="/images/img-powerblockgame.png"
              alt="image powerblockgame"
              width={460}
              height={410}
            />
          </div>
          <div className="content-item__content">
            <div className="content-item__content--title">
              {t("Power block game")}
            </div>
            <div className="content-item__content--content">
              <span>
                <Trans
                  i18nKey="Power block game content"
                  components={{
                    b: (
                      <span
                        className="text-link"
                        onClick={() =>
                          onToggleRule({
                            initTabSidebar: "2",
                            initActiveTab: "0"
                          })
                        }
                      ></span>
                    )
                  }}
                />
              </span>
            </div>
            <div className="content-item__content--button">
              <Link passHref href="/power-block-game" legacyBehavior>
                <button>{t("Play now")}</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="content-item item-numbergame">
          <div className="content-item__image">
            <Image
              src="/images/img-powerblock.png"
              alt="image powerblock"
              width={589}
              height={410}
            />
          </div>
          <div className="content-item__content">
            <div className="content-item__content--title text-uppercase">
              {t("Number game")}
            </div>
            <div className="content-item__content--content">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t("Number game content")
                }}
              ></span>
            </div>
            <div className="content-item__content--button">
              <Link passHref href="/number-game" legacyBehavior>
                <button>{t("Play now")}</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="content-item item-powerblock">
          <div className="content-item__image">
            <Image
              src="/images/img-numbergame.png"
              alt="image number game"
              width={668}
              height={450}
            />
          </div>
          <div className="content-item__content">
            <div className="content-item__content--title text-uppercase">
              {t("Power game")}
            </div>
            <div className="content-item__content--content">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t("Power game content")
                }}
              ></span>
            </div>
            <div className="content-item__content--button">
              <Link passHref href="/power-game" legacyBehavior>
                <button>{t("Coming soon")}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedGame
