import { isEmpty } from "lodash"
import { useTranslation } from "next-i18next"
import { useSelector } from "react-redux"
import { AUTH_UNAUTHENTICATED } from "@config/constants"

import Image from "@components/core/ImagePriority"

import { mapSSOUrl } from "@helpers/clientUtils"

const HowToStart = () => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const user = useSelector((state) => state.rootUser.user)
  const authCode = useSelector((state) => state.rootUser.authCode)
  const isAuthticated = !isEmpty(user) && !!user.id
  // const [href, setHref] = useState("")

  // useEffect(() => {
  //   setHref(window.location.href)
  // }, [dispatch])

  return (
    <div className="box-howtostart">
      <div className="box-title how-to-start">{t("How to start")}</div>
      <div className="box-desc">{t("How to start desc")}</div>

      <div className="list-item">
        <div className="list-item__item">
          <div className="list-item__item--image">
            <div className="icon icon-choose">
              <Image
                src="/images/icon-choose.svg"
                alt="icon choose"
                width={115}
                height={111}
              />
            </div>
          </div>
          <div className="list-item__item--title">{t("Join")}</div>
          {!isAuthticated && authCode === AUTH_UNAUTHENTICATED && (
            <div
              className="list-item__item--desc"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t("login content", {
                  link1: mapSSOUrl(`/register`),
                  link2: mapSSOUrl(`/login`)
                })
              }}
            ></div>
          )}
          {isAuthticated && (
            <div
              className="list-item__item--desc"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t("login content__is_auth")
              }}
            ></div>
          )}
        </div>

        <div className="list-item__item">
          <div className="list-item__item--image">
            <div className="icon icon-ticket">
              <Image
                src="/images/icon-ticket.svg"
                alt="icon ticket"
                width={115}
                height={111}
              />
            </div>
          </div>
          <div className="list-item__item--title">{t("Play join")}</div>
          <div className="list-item__item--desc">{t("Play join content")}</div>
        </div>
        <div className="list-item__item">
          <div className="list-item__item--image">
            <div className="icon icon-win">
              <Image
                src="/images/icon-jackpot.svg"
                alt="icon jackpot"
                width={115}
                height={111}
              />
            </div>
          </div>
          <div className="list-item__item--title">{t("Winner")}</div>
          <div className="list-item__item--desc">{t("Winner content")}</div>
        </div>
      </div>
    </div>
  )
}

export default HowToStart
