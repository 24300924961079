import React from "react"

import { useTranslation } from "next-i18next"

import Image from "@components/core/ImagePriority"

const WhyChooseUs = () => {
  const { t } = useTranslation()

  return (
    <div className="box-whychooseus">
      <div className="box-title">{t("why choose us")}</div>
      <div className="box-desc">{t("why choose us content")}</div>

      <div className="whychooseus-list">
        <div className="whychooseus-list__item">
          <div className="whychooseus-list__item--icon">
            <Image
              src="/images/icon-biggest.svg"
              alt="icon biggest"
              width={114}
              height={114}
            />
          </div>
          <div className="whychooseus-list__item--title">
            {t("Get 100% Value Prize")}
          </div>
        </div>

        <div className="whychooseus-list__item">
          <div className="whychooseus-list__item--icon">
            <Image
              src="/images/icon-reliable.svg"
              alt="icon biggest"
              width={117}
              height={114}
            />
          </div>
          <div className="whychooseus-list__item--title">
            {t("Reliable and Transparent")}
          </div>
        </div>

        <div className="whychooseus-list__item">
          <div className="whychooseus-list__item--icon">
            <Image
              src="/images/icon-safe.svg"
              alt="icon biggest"
              width={94}
              height={114}
            />
          </div>
          <div className="whychooseus-list__item--title">
            {t("Playing Safe and Secure ")}
          </div>
        </div>

        <div className="whychooseus-list__item">
          <div className="whychooseus-list__item--icon">
            <Image
              src="/images/icon-reward.svg"
              alt="icon biggest"
              width={104}
              height={114}
            />
          </div>
          <div className="whychooseus-list__item--title">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t("Instant Reward", {
                  link: `<a href=${`/faq#Prizes`}  alt="aeterisk-mark-fxce">(*)</a>`
                })
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
