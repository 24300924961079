import React, { useState } from "react"

import { get } from "lodash"
import { useTranslation } from "next-i18next"

import { useRouter } from "next/router"

import classNames from "classnames"
import { useSelector } from "react-redux"
import ModalCheckResult from "@components/others/ModalCheckResult"
import { getFormatDate } from "@helpers/utils"

const LotteryResults = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isOpenModalResult, setIsOpenModalResult] = useState(false)

  const dialedGames = useSelector((state) => {
    return get(state, "rootConfig.dialedGames", [])
  })

  const onToggleResult = () => {
    setIsOpenModalResult(!isOpenModalResult)
  }

  return (
    <div className="box-lotteryresults">
      <div className="box-title">{t("latest lottery results")}</div>
      <div className="box-desc">{t("latest lottery results desc")}</div>

      <div className="main-lottery-results">
        <div className="box-winning-number">
          <div className="lottery-results__title">{t("Winning Numbers")}</div>
          <div className="box-number">
            <div className="box-number-head">
              <div className="box-number-row">
                <div className="box-number-col">{t("Draw Date")}</div>
                <div className="box-number-col">{t("Result")}</div>
                <div className="box-number-col">{t("Tickets")}</div>
              </div>
            </div>
            <div className="box-number-body">
              {dialedGames.map((item) => {
                const { numbers = [] } = item
                return (
                  <div key={item.id} className="box-number-row">
                    <div className="box-number-col">
                      {getFormatDate(item.resultAtTimestamp, router.locale)}
                    </div>
                    <div className="box-number-col">
                      <div className="number">
                        {numbers.map((number, index) => {
                          return (
                            <div
                              key={`${number}_${index}`}
                              className={classNames("number__item", {
                                jackpot: index === 0
                              })}
                            >
                              {number}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="box-number-col col-ticket">
                      {item.totalPanel}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {/* <div className="box-our-winner">
          <div className="lottery-results__title">{t("Our Winners")}</div>
          <div className="list-winner">
            <div className="winner-item">
              <div className="winner-item__info">
                <div className="form-avatar">
                  <Image
                    src="/images/user-default.jpg"
                    alt="Banner page"
                    width={57}
                    height={57}
                  />
                </div>
                <div className="form-info">
                  <div className="form-info__name">NicoRobin 01</div>
                  <div className="form-info__detail">
                    <span className="powerblock">{t("Power Block")}</span>
                    <span className="date">20/05/2021</span>
                  </div>
                </div>
              </div>
              <div className="winner-item__wallet">
                <div className="icon-fxcewallet">
                  <span>345627891-2031-382</span>
                </div>
              </div>
            </div>

            <div className="winner-item">
              <div className="winner-item__info">
                <div className="form-avatar">
                  <Image
                    src="/images/user-default.jpg"
                    alt="Banner page"
                    width={57}
                    height={57}
                  />
                </div>
                <div className="form-info">
                  <div className="form-info__name">NicoRobin 01</div>
                  <div className="form-info__detail">
                    <span className="powerblock">{t("Power Block")}</span>
                    <span className="date">20/05/2021</span>
                  </div>
                </div>
              </div>
              <div className="winner-item__wallet">
                <div className="icon-fxcewallet">
                  <span>345627891-2031-382</span>
                </div>
              </div>
            </div>

            <div className="winner-item">
              <div className="winner-item__info">
                <div className="form-avatar">
                  <Image
                    src="/images/user-default.jpg"
                    alt="Banner page"
                    width={57}
                    height={57}
                  />
                </div>
                <div className="form-info">
                  <div className="form-info__name">NicoRobin 01</div>
                  <div className="form-info__detail">
                    <span className="powerblock">{t("Power Block")}</span>
                    <span className="date">20/05/2021</span>
                  </div>
                </div>
              </div>
              <div className="winner-item__wallet">
                <div className="icon-fxcewallet">
                  <span>345627891-2031-382</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="show-all-results">
        <div className="btn-see-more" onClick={() => onToggleResult()}>
          {t("See all results")}
        </div>
      </div>
      {isOpenModalResult && (
        <ModalCheckResult
          // scrollable
          isOpen={isOpenModalResult}
          toggle={onToggleResult}
          centered
          dialedGames={dialedGames}
        />
      )}
    </div>
  )
}

export default LotteryResults
